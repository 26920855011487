

import { Component, Watch, Mixins } from "vue-property-decorator";
import {
  ITableMember,
  ITableTask,
  ITableDay,
  ITableMode,
  ITableWeek, ITableMonthDay, ISelectedTableWeek, ITableNonBillable,
} from "@/types/time-table";
import {
  IProject,
  ITaskTime,
  IVacation,
  ISickDay,
  IHoliday,
  IWeekTime,
  ITeam,
  IWorkday,
  IDayData,
  CurrentUser,
  ITeamStatus,
  IComment,
  ITimePlan,
  IProjectTime,
  ITimePlanDetails
} from "@/types/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import minMax from "dayjs/plugin/minMax";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  getTotalMinutes,
  getMaxTime,
  getMinTime,
  getMonthDays,
  getQuarterFromWeekTimes,
  moveItemInArray,
  msToTime,
  tasksMsToTime,
  tasksPercentTimes,
  percentTimes,
  getDayColumnStyles,
  timeToMs,
} from "@/utils";
import {
  BugReport,
  DetailsView,
  Loader,
  TableSelect,
  TableContent,
  TableHeaderFilterSkills,
  TableHeader,
  SwitcherComponent
} from "@/components";
import { RouterMixin } from "@/mixins";
import config, {
  SERVICE_DATA_TEAM,
  SERVICE_DATA_POSITION,
  SERVICE_DATA_SKILLS,
  SERVICE_DATA_VACATION,
  SERVICE_DATA_SICK_DAY,
  SERVICE_DATA_HOLIDAY,
  SERVICE_DATA_TIMES,
  SERVICE_DATA_TIMES_CURRENT,
  SERVICE_DATA_WEEK_TIMES,
  SERVICE_DATA_WORKDAY,
  SERVICE_DATA_COMMENT,
  APP_VIEWS,
} from "../../config";
import { IGrouppedSkills, ILevel } from "@/types/skill-filter";

dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(relativeTime);

@Component({
  components: {
    BugReport,
    DetailsView,
    Loader,
    TableHeaderFilterSkills,
    TableHeader,
    TableContent,
    TableSelect,
    SwitcherComponent
  }
})
export default class TimeTable extends Mixins(RouterMixin) {
  private table_content_reload: number = 0;
  private selectedWeeks: ISelectedTableWeek[] = [];
  private selectedMonth: number = 0;
  private selectedYear: number = 0;
  private selectedProjects: IProject[] = [];
  private comments: IComment[] = [];
  private allProjects: IProject[] = [];
  private plannedProjects: IProject[] = [];
  private taskTimes: ITaskTime[] = [];
  private weekTimes: IWeekTime[] = [];
  private timePlans: ITimePlan[] = [];
  private timePlansDetails: ITimePlanDetails[] = [];
  private currentTaskTimes: ITaskTime[] = [];
  private team: ITeam[] = [];
  private vacations: IVacation[] = [];
  private sickDays: ISickDay[] = [];
  private holidays: IHoliday[] = [];
  private currentMonthHolidays: Record<string, IHoliday> = {};
  private workdays: IWorkday[] = [];
  private currentMonthWorkdays: Record<string, IWorkday> = {};
  private benchName: string = "BENCH";
  private members: Record<string, Record<string, ITableMember>> = {};
  private noPositionMembers: Record<string, any> = {};
  private dayDetails: any = null;
  private weekDetails: any = null;
  private userDetails: any = null;
  private lastReload: Date = new Date();
  private currentTimesLastReload: Date = new Date();
  private commentsLastReload: Date = new Date();
  private search: string = '';
  private searchTimeout: any;
  private positions: string[] = [];
  private skills: Record<string, string[]> = {};
  private selectedSkills: IGrouppedSkills[] = [];
  private membersDataUpd: string = '';
  private daysInMonthDataUpd: string = '';
  private initialComponentsLoaded: boolean = false;
  private showWorkloadPlanner: boolean = false;
  private innerAppDataLoading: boolean = false;
  private appDataLoaded: string[] = [];
  private appView: ITableMode = { name: "Quarter", value: "quarter" };
  private days: ITableMonthDay[] = getMonthDays(
      +this.$route.params.month - 1 || new Date().getMonth(),
      this.$route.params.year || new Date().getFullYear(),
      this.holidays,
      this.workdays,
  );
  private appTimesLoadTimeRange: { year: number; month: number } = { year: 0, month: 0 };
  private appWeekTimesLoadTimeRange: { year: number; month: number } =
      { year: 0, month: 0 };

  get teamStatus(): Record<string, ITeamStatus> {
    return this.$store.state.teamStatus
  }

  get benchId(): number {
    return this.$store.state.benchId
  }

  get currentUser(): CurrentUser {
    return this.$store.state.currentUser
  }

  get timeTableMonth() {
    return this.$store.state.timeTableMonth
  }

  get timeTableYear() {
    return this.$store.state.timeTableYear;
  }

  get timeTableView() {
    return this.$store.state.timeTableView;
  }

  get startDate(): Date | null {
    if (this.appView.value === "month") {
      return new Date(Date.UTC(this.selectedYear, this.selectedMonth, 1));
    } else {
      return this.quarter && this.quarter.length
          ? new Date(this.quarter[0].weeks[0].startDate)
          : null;
    }
  }

  get endDate(): Date | null {
    if (this.appView.value === "month") {
      return new Date(Date.UTC(this.selectedYear, this.selectedMonth + 1, 0));
    } else {
      if (!this.quarter || this.quarter.length < 1) return null;

      const lastQuarter = this.quarter[this.quarter.length - 1];
      const lastQuarterWeeks = lastQuarter.weeks || null;

      return lastQuarterWeeks && lastQuarterWeeks.length
          ? new Date(lastQuarterWeeks[lastQuarterWeeks.length - 1].endDate)
          : null;
    }
  }

  get daysInMonth() {
    return getMonthDays(this.selectedMonth, this.selectedYear, this.holidays, this.workdays);
  }

  get quarter() {
    return getQuarterFromWeekTimes(this.weekTimes);
  }

  get typingComment() {
    return this.$store.state.typingComment
  }

  get nonBillableProjects() {
    if (!this.allProjects) return []
    return this.allProjects.filter((project) => project.isNonBillable)
  }

  @Watch("$route")
  async changeRoute(): Promise<void> {
    const { month, year, projects, view } = this.routerParams;
    this.selectedMonth = month - 1;
    this.selectedYear = year;
    this.appView = APP_VIEWS[view];

    this.selectedProjects = projects
        ? this.allProjects.filter(p => projects.some(q => q === p.name))
        : [];

    this.setTeamMembers();

    if (
        this.selectedMonth !== this.timeTableMonth ||
        this.selectedYear !== this.timeTableYear ||
        this.appView !== this.timeTableView
    ) {
      this.$store.commit({
        type: "SET_STORE_FIELDS",
        payload: {
          field: ["timeTableView", "timeTableMonth", "timeTableYear"],
          value: [this.appView, this.selectedMonth, this.selectedYear],
        },
      });

      this.closeDetailsView();

      /* start of data loading */
      this.innerAppDataLoading = true;

      this.appDataLoaded = [];
      setTimeout(() => {
        this.appDataLoaded = [
          SERVICE_DATA_TEAM,
          SERVICE_DATA_VACATION,
          SERVICE_DATA_SICK_DAY,
          SERVICE_DATA_POSITION,
          SERVICE_DATA_SKILLS,
          SERVICE_DATA_HOLIDAY,
          SERVICE_DATA_WORKDAY,
          SERVICE_DATA_TIMES_CURRENT,
          SERVICE_DATA_COMMENT
        ]
      }, 100)

      if (this.appView.value === "month") {
        if (
            (this.appTimesLoadTimeRange.year !== this.selectedYear ||
                this.appTimesLoadTimeRange.month !== this.selectedMonth)
        ) {
          await this.fetchTimes();
          await this.fetchTimesPlanning();
          await this.fetchCurrentTimes();
        }
      } else {
        if (
            (this.appWeekTimesLoadTimeRange.year !== this.selectedYear ||
                this.appWeekTimesLoadTimeRange.month !== this.selectedMonth)
        ) {
          await this.fetchWeekTimes();
        }
      }

      await this.fetchComments();

      /* end of data loading */
      this.innerAppDataLoading = false;

      this.setHolidays(this.holidays);
      this.setWorkdays(this.workdays);
    }


    if (this.appView.value === "month") {
      this.setMembersTimes(this.taskTimes);
      this.setMembersTimes(this.currentTaskTimes);
    } else {
      this.setMembersWeekTimes(this.weekTimes);
    }

    this.setMembersComments(this.comments);
    this.setMembersVacations(this.vacations, SERVICE_DATA_VACATION);
    this.setMembersVacations(this.sickDays, SERVICE_DATA_SICK_DAY);
  }

  async created(): Promise<void> {
    const { month, year, projects, view } = this.routerParams;
    this.selectedMonth = month - 1;
    this.selectedYear = year;
    this.appView = APP_VIEWS[view];

    this.$store.commit({
      type: "SET_STORE_FIELDS",
      payload: {
        field: ["timeTableView", "timeTableMonth", "timeTableYear"],
        value: [this.appView, this.selectedMonth, this.selectedYear],
      },
    });

    /* start of data loading */
    this.innerAppDataLoading = true;

    await this.fetchTeam();
    this.setTeamMembers();
    this.setSkillGropped();

    try {
      this.$store.dispatch("AccessTeamStatusDataAsync");
    } catch(err) {
      console.warn(err);
    }

    this.showWorkloadPlanner =
        this.currentUser && this.currentUser.hasFullAccess ||
        this.team && this.team.some(
            (member) =>
                member.name === this.currentUser.name && member.position === "PM"
        );

    this.initialComponentsLoaded = true;

    if (this.appView.value === "month") {
      await this.fetchTimes();
      await this.fetchCurrentTimes();

      this.setMembersTimes(this.taskTimes);
      this.setMembersTimes(this.currentTaskTimes);
    } else {
      await this.fetchWeekTimes();

      this.setMembersWeekTimes(this.weekTimes);
    }
    await this.fetchTimesPlanning();
    await this.fetchComments();
    await this.fetchVacations();
    await this.fetchSickDays();
    await this.fetchHolidays();
    await this.fetchWorkdays();
    await this.fetchAllProjects();

    /* end of data loading */
    this.innerAppDataLoading = false;

    this.selectedProjects = projects
        ? this.allProjects.filter((p) => projects.some((q) => q === p.name))
        : [];

    this.setMembersComments(this.comments)
    this.setMembersVacations(this.vacations, SERVICE_DATA_VACATION);
    this.setMembersVacations(this.sickDays, SERVICE_DATA_SICK_DAY);
    this.setHolidays(this.holidays);
    this.setWorkdays(this.workdays);

    this.setDataRefreshJob();
    this.days = getMonthDays(this.selectedMonth, this.selectedYear, this.holidays, this.workdays);
    Object.values(this.currentMonthHolidays)
        .forEach(h => typeof h.dayInMonth === 'number' && this.days[h.dayInMonth - 1].holiday && (this.days[h.dayInMonth - 1].holiday = { ...h }))
  }

  async fetchVacations(){
    try {
      this.vacations = await this.handleApiServiceRequest("GetVacations", false, SERVICE_DATA_VACATION);
    } catch (_e) {
      this.vacations = []
    }
  }
  async fetchSickDays(){
    try {
      this.sickDays = await this.handleApiServiceRequest("GetSickDays", false, SERVICE_DATA_SICK_DAY);
    } catch(_e) {
      this.sickDays = [];
    }
  }
  async fetchHolidays(){
    try {
      this.holidays = await this.handleApiServiceRequest("GetHolidays", false, SERVICE_DATA_HOLIDAY);
    } catch(_e) {
      this.holidays = []
    }
  }
  async fetchWorkdays() {
    try {
      this.workdays = await this.handleApiServiceRequest("GetWorkdays", false, SERVICE_DATA_WORKDAY)
    } catch(_e) {
      this.workdays = []
    }
  }

  async fetchTeam(){
    try {
      [this.team, this.positions, this.skills] = await Promise.all([
        this.handleApiServiceRequest("GetTeam", false, SERVICE_DATA_TEAM),
        this.handleApiServiceRequest("GetPositions", false, SERVICE_DATA_POSITION),
        this.handleApiServiceRequest("GetSkills", false, SERVICE_DATA_SKILLS)
      ]);
    } catch(_e) {
      this.team = [];
      this.positions = [];
      this.skills = {};
    } finally {
      this.$store.commit({
        type: 'SET_ACTIVE_POSITION',
        payload: {positions: this.positions}
      });
    }
  }

  async fetchTimes() {
    try {
      const timesResponse = await this.$store.dispatch("GetTimes", {
        startDate: this.startDate,
        endDate: this.endDate,
        removeLoaderFlag: false
      });

      if (!timesResponse) return;

      this.appTimesLoadTimeRange = {
        year: this.selectedYear,
        month: this.selectedMonth,
      };

      this.appDataLoaded.push(SERVICE_DATA_TIMES);
      // this.projects = timesResponse.projects;
      this.taskTimes = timesResponse.times;

      this.$store.commit({
        type: "SET_STORE_FIELDS",
        payload: {
          field: [
            "shortCacheUpdatedAt",
            "longCacheUpdatedAt",
            "teamCacheUpdatedAt",
            "teamPositionsCacheUpdatedAt",
          ],
          value: [
            dayjs(timesResponse.shortCacheUpdatedAt).format("HH:mm ddd"),
            dayjs(timesResponse.longCacheUpdatedAt).format("HH:mm ddd"),
            dayjs(timesResponse.teamCacheUpdatedAt).format("HH:mm ddd"),
            dayjs(timesResponse.teamPositionsCacheUpdatedAt).format("HH:mm ddd"),
          ],
        },
      });

      this.moveBenchToTop();
    } catch(_e) {
      this.appDataLoaded.push(SERVICE_DATA_TIMES);
      // this.projects = [];
      this.taskTimes = [];
    }
  }

  async fetchCurrentTimes() {
    this.currentTaskTimes = await this.$store
        .dispatch("GetTimesCurrent", { removeLoaderFlag: false })
        .then((tasks) => {
          tasks && tasks.forEach((taskTime) => (taskTime.endDate = undefined));
          return tasks;
        }).catch((_e) => {
          return []
        });
  }

  async fetchWeekTimes() {
    try {
      const weekTimesResponse = await this.$store.dispatch("GetWeekTimes", {
        month: this.selectedMonth + 1,
        year: this.selectedYear,
        offset: -new Date(Date.UTC(this.selectedYear, this.selectedMonth, 1)).getTimezoneOffset(),
        removeLoaderFlag: false,
      });

      if (!weekTimesResponse) return;

      this.appDataLoaded.push(SERVICE_DATA_WEEK_TIMES);
      // this.projects = this.getProjectFromWeekTimes(weekTimesResponse);

      this.weekTimes = [...weekTimesResponse];
      this.moveBenchToTop();
    } catch(_e) {
      console.log(_e);
      this.weekTimes = []
    } finally {
      this.appWeekTimesLoadTimeRange = {
        year: this.selectedYear,
        month: this.selectedMonth,
      };
    }
  }

  moveBenchToTop() {
    let benchIndex = this.allProjects.map((z) => z.id).indexOf(this.benchId);
    if (benchIndex !== -1) {
      this.allProjects[benchIndex].name = this.benchName;
      moveItemInArray(this.allProjects, benchIndex, 0);
    }
  }

  async fetchComments() {
    try {
      const commentResponse = await this.$store.dispatch("GetComments",{
        startDate: this.startDate,
        endDate: this.endDate,
        removeLoaderFlag: false
      });

      if(!commentResponse || !commentResponse.length) return;

      this.comments = commentResponse;
    } catch (_e) {
      this.comments = []
    } finally {
      this.appDataLoaded.push(SERVICE_DATA_COMMENT);
    }
  }

  async handleApiServiceRequest(
      serviceRequest: string,
      removeLoaderFlag: boolean,
      dataName: string
  ) {
    const request = await this.$store.dispatch(serviceRequest, {
      removeLoaderFlag,
    });
    this.appDataLoaded.push(dataName);
    return request;
  }

  async refreshData() {
    /* start of data loading */
    this.innerAppDataLoading = true;

    this.appDataLoaded = [];

    setTimeout(() => {
      this.appDataLoaded = [SERVICE_DATA_POSITION, SERVICE_DATA_HOLIDAY];
    }, 100);

    // removeLoaderFlag: true need for remove start loader

    await Promise.all([
      this.appView.value === "month"
          ? this.fetchTimes()
          : this.fetchWeekTimes(),
      this.fetchCurrentTimes(),
      this.fetchTimesPlanning(),
      this.fetchComments(),
      this.fetchVacations(),
      this.fetchSickDays(),
      this.fetchHolidays(),
      this.fetchWorkdays(),
    ]);

    /* end of data loading */
    this.innerAppDataLoading = false;
    this.setTeamMembers();

    if (this.appView.value === "month") {
      this.setMembersTimes(this.taskTimes);
      this.setMembersTimes(this.currentTaskTimes);
    } else {
      this.setMembersWeekTimes(this.weekTimes);
    }

    this.setMembersComments(this.comments);
    this.setMembersVacations(this.vacations, SERVICE_DATA_VACATION);
    this.setMembersVacations(this.sickDays, SERVICE_DATA_SICK_DAY);
    this.setHolidays(this.holidays);
    this.setWorkdays(this.workdays)
  }

  changeDateRange({ year, month }): void {
    this.selectedYear = year
    this.selectedMonth = month
    this.$router.push({ path: `/${this.selectedMonth}/${this.selectedYear}`, query: { ...this.$route.query } })
  }

  changeProjects(value): void {
    this.selectedProjects = [...value];

    const path = `/${this.selectedMonth + 1}/${this.selectedYear}`;

    if (this.selectedProjects.length) {
      this.$router.push({
        path,
        query: {
          view: this.appView.value,
          projects: encodeURIComponent(
              this.selectedProjects.map((s) => s.name).join()
          ),
        },
      });
    } else {
      this.$router.push({ path, query: { view: this.appView.value } });
    }
  }

  changeMode(value: ITableMode) {
    const path = `/${this.selectedMonth + 1}/${this.selectedYear}`;

    this.appView = value;
    this.closeDetailsView();

    if (window.localStorage) {
      window.localStorage.setItem('view', value.value)
    }

    this.$router.push({
      path,
      query: { ...this.$route.query, view: value.value },
    });
  }

  closeDetailsView() {
    this.selectedWeeks = [];
    this.timePlansDetails = [];
    this.dayDetails = null;
    this.weekDetails = null;
    this.userDetails = null;
  }

  isBench(project: IProject) {
    return project.isNonBillable;
  }

  setHolidays(holidays: IHoliday[]): void {
    this.currentMonthHolidays = (holidays || []).reduce((all, holyday) => {
      const { endDate, startDate } = holyday;

      if (!endDate || !startDate) return all;

      const holydayEndDate = new Date(holyday.endDate!.toString());
      const holydayStartDate = new Date(holyday.startDate!.toString());

      const endDateMonth = holydayEndDate.getMonth();
      const endDateYear = holydayEndDate.getFullYear();

      const startDateMonth = holydayStartDate.getMonth();
      const startDateYear = holydayStartDate.getFullYear();

      const { selectedYear: year, selectedMonth: month } = this;

      if (year !== endDateYear && year !== startDateYear) return all;
      if (month !== endDateMonth && month !== startDateMonth) return all;

      const dayMS = 86400000;

      const startDateDayInMonth = holydayStartDate.getDate();
      const endDateDayInMonth = holydayEndDate.getDate();

      if (endDateMonth === startDateMonth) {
        const numberOfDays =
            (Date.parse(endDate) - Date.parse(startDate)) / dayMS + 1;
        Array.from(
            { length: numberOfDays },
            (_, x) =>
                (all[startDateDayInMonth - x] = {
                  ...holyday,
                  dayInMonth: startDateDayInMonth + x,
                })
        );
      } else if (startDateMonth === month) {
        const lastMonthDay = new Date(startDateYear, startDateMonth + 1, 0);
        const numberOfDays =
            Math.floor(
                (Date.parse(lastMonthDay.toDateString()) - Date.parse(startDate)) /
                dayMS
            ) + 2;
        Array.from(
            { length: numberOfDays },
            (_, x) =>
                (all[startDateDayInMonth - x] = {
                  ...holyday,
                  dayInMonth: startDateDayInMonth + x,
                })
        );
      } else if (endDateMonth === month) {
        const numberOfDays = endDateDayInMonth;
        Array.from(
            { length: numberOfDays },
            (_, x) => (all[x] = { ...holyday, dayInMonth: x + 1 })
        );
      }

      return all
    }, {} as Record<string, IHoliday>)

    Object.values(this.currentMonthHolidays)
        .forEach(h => typeof h.dayInMonth === 'number' && this.days[h.dayInMonth - 1].holiday && (this.days[h.dayInMonth - 1].holiday = { ...h }))

    this.daysInMonthDataUpd = new Date().toLocaleString()
  }


  setWorkdays(workdays: IWorkday[]): void {
    this.currentMonthWorkdays = (workdays || []).reduce((all, workday) => {
      const { endDate, startDate } = workday

      if (!endDate || !startDate) return all

      const workdayEndDate = new Date(workday.endDate!.toString())
      const workdayStartDate = new Date(workday.startDate!.toString())

      const endDateMonth = workdayEndDate.getMonth()
      const endDateYear = workdayEndDate.getFullYear()

      const startDateMonth = workdayStartDate.getMonth()
      const startDateYear = workdayStartDate.getFullYear()

      const { selectedYear: year, selectedMonth: month } = this

      if (year !== endDateYear && year !== startDateYear) return all
      if (month !== endDateMonth && month !== startDateMonth) return all

      const dayMS = 86400000

      const startDateDayInMonth = workdayStartDate.getDate()
      const endDateDayInMonth = workdayEndDate.getDate()

      if (endDateMonth === startDateMonth) {
        const numberOfDays = (Date.parse(endDate) - Date.parse(startDate)) / dayMS + 1
        Array.from({ length: numberOfDays }, (_, x) => all[startDateDayInMonth - x] = { ...workday, dayInMonth: startDateDayInMonth + x })
      }
      else if (startDateMonth === month) {
        const lastMonthDay = new Date(startDateYear, startDateMonth + 1, 0)
        const numberOfDays = Math.floor((Date.parse(lastMonthDay.toDateString()) - Date.parse(startDate)) / dayMS) + 2
        Array.from({ length: numberOfDays }, (_, x) => all[startDateDayInMonth - x] = { ...workday, dayInMonth: startDateDayInMonth + x })
      }
      else if (endDateMonth === month) {
        const numberOfDays = endDateDayInMonth
        Array.from({ length: numberOfDays }, (_, x) => all[x] = { ...workday, dayInMonth: x + 1 })
      }

      return all
    }, {} as Record<string, IWorkday>)

    Object.values(this.currentMonthWorkdays)
        .forEach(h => typeof h.dayInMonth === 'number' && (this.daysInMonth[h.dayInMonth - 1].workday = { ...h }))

    this.daysInMonthDataUpd = new Date().toLocaleString()
  }
  setTeamMembers(): void {
    this.noPositionMembers = {};

    const QUARTER_WEEKS_COUNT = 15;

    const membersPositions = this.positions ? this.positions.reduce((all, position) => {
      all[position] = {};
      return all;
    }, {} as Record<string, any>) : {};

    this.members = this.team ? this.team.reduce((team, member) => {
      const { position, name } = member;

      if (!team[position][name]) {
        team[position][name] = {
          ...member,
          isRunning: false,
          isBenching: false,
          days: this.daysInMonth.reduce((days, day) => {
            days[day.number] = {
              tasks: [],
              currentTask: null,
              vacation: null,
              sickDay: null,
              holiday: null,
              workday: null,
            };
            return days;
          }, {} as Record<string, Partial<ITableDay>>),
          weeks: Array.from({ length: QUARTER_WEEKS_COUNT }, (_) => {
            return {
              vacation: 0,
              sickDay: 0,
              projectsTimes: [],
              startDate: null,
              endDate: null,
              isCurrent: false,
            };
          }),
        };
      }

      return team
    }, membersPositions) : {}

    this.noPositionMembers = this.members['-- No Position --'] || {};
    this.members['-- No Position --'] = {};
  }

  setSkillGropped(): void {
    this.selectedSkills = Object.keys(this.skills)
      .map(key => ({
        grouppedName: key,
        isOpened: false,
        levels: this.skills[key]
          .map(val => ({ level: val, checked: false})) as ILevel[]
      })) as IGrouppedSkills[];
  }

  setMembersComments(comments: IComment[]): void {
    comments && comments.forEach(comment => {
      if(!comment.user && !comment.userWhoLeftNote) {
        return;
      }

      const member = this.members[comment.user.position][comment.user.name];
      const date = new Date(comment.date);
      const dayNumber = date.getDate();
      const monthNumber = date.getMonth();
      const yearNumber = date.getFullYear();

      if(monthNumber === this.selectedMonth && yearNumber === this.selectedYear) {
        const dayData = member.days[dayNumber];

        if(!dayData.comments)
          dayData.comments = [];

        comment.isEditing = false;
        dayData.comments = dayData.comments.filter(com => com.id != comment.id);
        dayData.comments.push(comment);
      }
    });
  }

  clearMembersComments(dayData: IDayData, userDeteils: ITableMember): void {
    const member = this.members[userDeteils.position][userDeteils.name];
    const date = new Date(dayData.fullDate);
    const dayNumber = date.getDate();
    const monthNumber = date.getMonth();
    const yearNumber = date.getFullYear();

    if(monthNumber === this.selectedMonth && yearNumber === this.selectedYear) {
      const dayData = member.days[dayNumber];

      dayData.comments = [];
    }
  }

  setMembersVacations(terms: IVacation[] | ISickDay[], field: string): void {
    if(!terms || terms.length === 0)
      return;

    const members =
        Object.values(this.members)
            .reduce((all, item) => { return Object.assign(all, item) }, {}) as Record<string, ITableMember>

    terms.forEach(term => {
      if (!term.user) return;
      if (!term.startDate && !term.endDate) return;

      if (!term.startDate && term.endDate) term.startDate = term.endDate;
      if (term.startDate && !term.endDate) term.endDate = term.startDate;

      let startDate = new Date(term.startDate!);
      let endDate = new Date(term.endDate!);

      const memberName = term.user.name;
      const member = members[memberName];

      if (!member) return;

      const memberPosition = member.position;

      if (!memberPosition) return;

      if (!this.endDate || !this.startDate) return;
      if (startDate <= this.endDate && endDate >= this.startDate) {
        if(startDate < this.startDate){
          startDate = new Date(this.startDate);
        }

        if(endDate > this.endDate){
          endDate = new Date(this.endDate);
        }

        while (startDate <= endDate) {
          if (this.appView.value === "month") {
            this.members[memberPosition][memberName].days[startDate.getDate()][
                field
                ] = term;
          } else {
            let week: ITableWeek = this.members[memberPosition][
                memberName
                ].weeks.filter((item) => {
              return (
                  new Date(item.startDate).getTime() <= startDate.getTime() &&
                  new Date(item.endDate).getTime() >= startDate.getTime()
              );
            })[0];
            if (week) {
              week[field] = week[field] ? week[field] + 1 : 1;
              if (!week[field + 's']) {
                week[field + 's'] = [term]
              } else {
                if (!week[field + 's'].find((item: IVacation) => item.id === term.id)) {
                  week[field + 's'].push(term)
                }
              }
            }
          }

          startDate.setDate(startDate.getDate() + 1);
        }
      }
    });

    this.membersDataUpd = new Date().toLocaleString();
  }

  setMembersTimes(taskTimes: ITaskTime[]): void {
    taskTimes &&
    taskTimes.forEach((timeItem) => {
      if (!timeItem.user) {
        return;
      }

      const user = timeItem.user;

      if (user.position === '-- No Position --') {
        this.$set(this.members['-- No Position --'], user.name, this.noPositionMembers[user.name])
      }

      const dayDataExists = !!(user.name && user.position && this.members[user.position] && this.members[user.position][user.name])
      if (!dayDataExists) return;

      const member = this.members[user.position][user.name];
      const timeStartDate = new Date(timeItem.startDate);
      const dayNumber = timeStartDate.getDate();
      const monthNumber = timeStartDate.getMonth();
      const yearNumber = timeStartDate.getFullYear();

      if(!timeItem.endDate){
        if(this.isBench(timeItem.task.project)){
          member.runningColor = timeItem.task.project.timesheetColor && timeItem.task.project.timesheetColor.length > 0 ? timeItem.task.project.timesheetColor : config.workloadStyles["--benching"]
        } else {
          member.runningColor = config.workloadStyles["--workload-planner-running"]
        }
      }

      if(monthNumber === this.selectedMonth && yearNumber === this.selectedYear){
        const dayData = member.days[dayNumber];

        if (this.selectedProjects.length) {
          const projectId = timeItem.task.project.id;
          const spContainsTask = this.selectedProjects.some(sp => sp.id === projectId)
          if (!spContainsTask) return
        }

        const task: ITableTask = {
          startTime: dayjs(new Date(timeItem.startDate)).format("HH:mm"),
          endTime: timeItem.endDate && dayjs(new Date(timeItem.endDate)).format("HH:mm"),
          duration: timeItem.duration,
          name: !timeItem.task.parent ? timeItem.task.name : `${timeItem.task.parent.name} → ${timeItem.task.name}`,
          id: timeItem.task.id,
          project: timeItem.task.project,
          // createdDate: timeItem.createdDate,
          createdDateHoursDiff: dayjs(timeItem.lastUpdatedDate).diff(timeItem.endDate, "hours"),
          lastUpdatedDate: timeItem.lastUpdatedDate
        };
        if(!task.endTime){
          dayData.currentTask = task;
        }else{
          dayData.tasks.push(task);
        }
        this.calculateMembersDayData(dayData);
      }
    })
  }

  setMembersWeekTimes(weekTimes: IWeekTime[]): void {
    const settedUsers = {};
    const now = new Date().getTime();

    weekTimes &&
    weekTimes.forEach((weekItem) => {
      const { startDate, endDate } = weekItem;

      const currentWeekItemUsersTimes = weekItem.usersTimes;
      const startTime = new Date(startDate).getTime();
      const endTime = new Date(endDate).getTime();
      const isCurrent = now >= startTime && now <= endTime;

      for (const memberPosition in this.members) {
        for (const userName in this.members[memberPosition]) {
          const user = this.members[memberPosition][userName];
          if (!user || !user.id) return;

          if (!settedUsers[`${memberPosition}-${userName}`]) {
            user.weeks = [];
            settedUsers[`${memberPosition}-${userName}`] = true;
          }

          let projectsTimes =
              currentWeekItemUsersTimes && currentWeekItemUsersTimes[user.id]
                  ? currentWeekItemUsersTimes[user.id].projectsTimes
                  : [];

          if (this.selectedProjects.length) {
            projectsTimes = projectsTimes.filter((pt) =>
                this.selectedProjects.some((sp) => sp.id === pt.id)
            );
          }

          const getIntersectionHoliday = (holidays: IHoliday[] = [], start: Date, end: Date): IHoliday[] => {
            return holidays.reduce((acc, holiday) => {
              if (start <= new Date(holiday.endDate) && new Date(holiday.startDate) <= end) {
                acc.push(holiday)
              }
              return acc
            }, [] as IHoliday[])
          }

          const getIntersectionDate = (start1: number, end1: number, start2: number, end2: number) => {
            const intersectionDate = start1 <= end2 && start2 <= end1
            if (!intersectionDate) return []

            const startIntersection = Math.max(start1, start2)
            const endIntersection = Math.min(end1, end2)

            const days: Date[] = []

            const start = new Date(startIntersection);
            const end = new Date(endIntersection);

            let loop = new Date(start);
            while (loop <= end) {
              days.push(new Date(loop))
              let newDate = loop.setUTCDate(loop.getUTCDate() + 1);
              loop = new Date(newDate);
            }

            return days
          }

          const intersectionHolidays = getIntersectionHoliday(this.holidays, new Date(startTime), new Date(endTime))


          const holidaysDate = intersectionHolidays.reduce((acc, item) => {
            const days  = getIntersectionDate(startTime, endTime, new Date(item.startDate).getTime(), new Date(item.endDate).getTime())
            if(days.length) {
              acc.push(...days)
            }
            return acc
          }, [] as Date[])

          const getDateNoWeekend = (days: Date[]): Date[] => {
            return days.reduce((acc, day) => {
              if (day.getDay() !== 0 && day.getDay() !== 6) acc.push(day)
              return acc
            }, [] as Date[])
          }

          const holiday = getDateNoWeekend(holidaysDate).length || 0

          const weekData = {
            startDate,
            endDate,
            projectsTimes,
            isCurrent,
            holiday,
            nonBillable: [],
            nonBillableProjectTimes: []
          };

          if (weekData.projectsTimes.length) {
            this.calculateMembersWeekData(weekData);
          }
          user.weeks.push(weekData);
        }
      }
    });
  }

  nonBillableTasksByColors(tasks: ITableTask[]) {
    if (!tasks || (tasks && !tasks.length)) return null
    return tasks.reduce((acc, curr) => {
      const color = curr.project.timesheetColor || config.workloadStyles["--benching"]
      if (!acc[color]) acc[color] = []
      acc[color].push(curr)
      return acc
    }, {}) as Record<string, ITableTask[]>
  }

  calculateMembersDayData(dayData: ITableDay){
    const tasks = dayData.currentTask ? [...dayData.tasks, dayData.currentTask] : dayData.tasks;
    const length = tasks.length;

    dayData.duration = tasksMsToTime(tasks);
    dayData.percent = tasksPercentTimes(tasks);

    const bench = tasks.filter(task => this.isBench(task.project));
    const nonBillableByColors = this.nonBillableTasksByColors(bench)
    const tasksMinutes = getTotalMinutes(tasks);
    const workingDayMinutes = 480;
    const total = tasksMinutes > workingDayMinutes ? tasksMinutes : workingDayMinutes;

    dayData.benchingPercent = 0

    const nonBillableList:
        Record<string, { percentsFromTotalTime: number, percentsFromWorkingDay: number }> = {}

    for (const nonBillable in nonBillableByColors) {
      const nonBillableMinutes = getTotalMinutes(nonBillableByColors[nonBillable]);
      if (!nonBillableList[nonBillable]) nonBillableList[nonBillable] = {
        percentsFromTotalTime: 0,
        percentsFromWorkingDay: 0
      }

      nonBillableList[nonBillable].percentsFromTotalTime = nonBillableList[nonBillable].percentsFromTotalTime + (nonBillableMinutes / total) * 100
      nonBillableList[nonBillable].percentsFromWorkingDay = nonBillableList[nonBillable].percentsFromWorkingDay + tasksPercentTimes(nonBillableByColors[nonBillable])

      dayData.benchingPercent += (nonBillableMinutes / total) * 100
    }

    dayData.startTime = length ? getMinTime(tasks, tasks[0].startTime) : null;
    dayData.endTime = length ? getMaxTime(tasks, tasks[0].startTime) : null;
    dayData.style = length
        ? getDayColumnStyles(
            dayData.duration,
            dayData.percent,
            nonBillableList
        )
        : null;
  }

  calculateMembersWeekData(weekData: ITableWeek) {
    const { holiday = 0 } = weekData;
    const WEEK_TOTAL_MINUTES = 60 * 8 * (5 - holiday);

    const nonBillableProjectTimes = weekData.projectsTimes.filter((project) =>
        this.isBench(project)
    );

    const totalMsDuration = weekData.projectsTimes.reduce(
        (total, curr) => +total + +curr.durationMs,
        0
    );

    const nonBillableProjectTimesByColor = nonBillableProjectTimes.reduce((acc, curr) => {
      const color = curr.timesheetColor || config.workloadStyles["--benching"]
      if (!acc[color]) acc[color] = []
      acc[color].push(curr)
      return acc
    }, {} as Record<string, IProjectTime[]>)

    const nonBillableDurations: ITableNonBillable[] = []

    for (const color in nonBillableProjectTimesByColor) {
      const durationMs = nonBillableProjectTimesByColor[color].reduce((acc, curr) => +acc + +curr.durationMs, 0)
      const duration = msToTime(durationMs)
      const percent = percentTimes(
          durationMs,
          WEEK_TOTAL_MINUTES
      );
      const projectTimes = nonBillableProjectTimesByColor[color]

      nonBillableDurations.push({ color, duration, percent, projectTimes, durationMs })
    }

    weekData.nonBillable = [...nonBillableDurations];

    const benchesTotalTime = weekData.nonBillable.reduce(
        (total, current) => +total + +current.durationMs,
        0
    );
    const totalDurationWithoutBenches = totalMsDuration - benchesTotalTime;

    weekData.duration = msToTime(totalDurationWithoutBenches);
    weekData.benchingDuration = msToTime(benchesTotalTime);
    weekData.benchingPercent = percentTimes(
        benchesTotalTime,
        WEEK_TOTAL_MINUTES
    );
    weekData.workingPercent = (totalDurationWithoutBenches / (WEEK_TOTAL_MINUTES * 60 * 1000)) * 100
  }

  setDataRefreshJob() {
    let currTimePromise;
    let commentsPromise;
    setInterval(() => {
      const lastReloadFromNow = dayjs(this.lastReload).fromNow();

      this.$store.commit({
        type: "SET_STORE_FIELDS",
        payload: {
          field: ['lastReloadFromNow'],
          value: [lastReloadFromNow],
        }
      })

      if (document.hidden) {
        return;
      }

      const timeNow = new Date().getTime();

      this.days = getMonthDays(this.selectedMonth, this.selectedYear, this.holidays, this.workdays);
      Object.values(this.currentMonthHolidays)
          .forEach(h => typeof h.dayInMonth === 'number' && this.days[h.dayInMonth - 1].holiday && (this.days[h.dayInMonth - 1].holiday = { ...h }));

      if ((timeNow - this.lastReload.getTime()) / 1000  >= 10 * 60) { // 10 mins data freshness
        this.refreshData();
        this.lastReload = new Date();
        this.currentTimesLastReload = new Date();
      }
      else if((timeNow - this.currentTimesLastReload.getTime()) / 1000 >= 3 * 60){ // 3 min
        if(currTimePromise) return;

        currTimePromise = this.fetchCurrentTimes().then(() => {
          this.currentTimesLastReload = new Date();
          if (this.appView.value === "month") {
            this.setMembersTimes(this.currentTaskTimes);
          }
        }).finally(() => {
          currTimePromise = null;
        });
      }
      else if(((timeNow - this.commentsLastReload.getTime()) / 1000 >= 1 * 60) && !this.typingComment){ // 1 min
        if(commentsPromise) return;

        const oldComments = JSON.stringify(this.comments)
        commentsPromise = this.fetchComments().then(() => {
          this.commentsLastReload = new Date();
          this.setMembersComments(this.comments);
        }).finally(() => {
          commentsPromise = null;
        });

        if (oldComments !== JSON.stringify(this.comments)) {
          this.table_content_reload++
        }

      }

    }, 5 * 1000); //5 sec check interval
  }

  showPlanned(data: { data: any; user: any, daysRange }) {
    const daysRange = data.daysRange.days;
    const endDate = dayjs(daysRange[daysRange.length - 1].fullDate).add(2,'days').format('YYYY-MM-DDTHH:mm:ss');
    const startDate = dayjs(daysRange[0].fullDate).format('YYYY-MM-DDTHH:mm:ss');

    const plans = this.timePlans
        .filter((plan) => plan.startDate === startDate || plan.endDate === endDate)
        .map((plan) => ({
          ...plan,
          plans: plan.plans[data.user.id] || []
        }));
    this.dayDetails = null;
    this.weekDetails = null;
    this.timePlansDetails = plans;
    this.userDetails = data.user;
  }

  showDayDetails(data: { data: any; user: any; daysGroup: any }) {
    this.weekDetails = null;
    this.timePlansDetails = [];
    this.dayDetails = {
      ...data.data,
      daysGroup: data.daysGroup,
    };
    this.userDetails = data.user;
  }

  async fetchAllProjects() {
    const projects = await this.$store.dispatch('GetAllProjects');
    this.allProjects = projects;
    this.plannedProjects = projects;
  }

  async fetchTimesPlanning() {
    const response: ITimePlanDetails[] = await this.$store.dispatch('GetTimesPlanning', { StartDate: this.startDate, EndDate: this.endDate });

    const timesPlans = response.map((timePlans) => {
      const plans = timePlans.plans.reduce((allPlans, plan) => {
        const userId = plan.userId;
        if (!allPlans[userId]) {
          allPlans[userId] = [];
        }
        return {
          ...allPlans,
          [userId]: [...allPlans[userId], plan] || []
        }
      }, {});
      return {
        ...timePlans,
        plans
      }
    }, []);

    this.timePlans = timesPlans;
  }

  showWeekDetails(data:
    { workloadData: any; week: any; user: any, event: KeyboardEvent, index: number, selectedWeeks, workloadDataWeeks }
  ) {
    if (!data) {
      return this.closeDetailsView();
    }

    this.weekDetails = {
      weekDetails: data.week,
    };

    const selectedWeeks = data.selectedWeeks.map((selectedWeek) => {
      return {
        ...selectedWeek,
        projectsTimes: selectedWeek.projectsTimes.length > 0 ?
            selectedWeek.projectsTimes.map((project) => {
              const editedUserName = this.team.find(({ id }) => project.editedUserId === id);
              return {
                ...project,
                editedUserName: editedUserName ? editedUserName.name : null,
              }
            })
            : []
      }
    });

    this.dayDetails = null;
    this.userDetails = data.user;
    this.selectedWeeks = selectedWeeks;
  }

  async createUserProject({ formData, userDetails }) {
    const { projectId, isAsap, plan } = formData;
    const selectedWeeks = this.selectedWeeks;
    const userId = selectedWeeks[0].id;
    const plans = selectedWeeks.map(({ endDate, startDate }) => {
      return {
        endDate,
        startDate,
        totalMilliseconds: timeToMs(plan).totalMilliseconds,
        projectId,
        userId,
        isAsap,
        id: null,
      }
    });
    const response = await this.$store.dispatch('SaveProject', { plans });

    const weekTimes = this.weekTimes.map((weekTime) => {
      const currentWeek = response.find((selectedWeek) => selectedWeek.startDate === weekTime.startDate && selectedWeek.endDate === weekTime.endDate);
      if (currentWeek) {
        const userProjects =
            weekTime.usersTimes &&
            weekTime.usersTimes[userId] &&
            weekTime.usersTimes[userId].projectsTimes || [];
        const { plans: [newProject] } = response.find((plan) => plan.startDate === currentWeek.startDate && plan.endDate === currentWeek.endDate);
        const { isNonBillable, timesheetColor } = this.allProjects.find((project) => project.id === newProject.projectId) || { timesheetColor: null, isNonBillable: false }
        const updatedProjects = [
          ...userProjects,
          {
            ...newProject,
            id: newProject.projectId,
            planMs: newProject.totalMilliseconds,
            planId: newProject.id,
            name: newProject.projectName,
            durationMs: 0,
            isNonBillable,
            timesheetColor
          }
        ];

        return {
          ...weekTime,
          usersTimes: {
            ...weekTime.usersTimes,
            [userId]: {
              projectsTimes: updatedProjects
            }
          }
        }

      }
      return weekTime;
    });

    const timePlans = this.timePlans.map((timePlan) => {
      const foundPlans = response.find((selectedWeek) => selectedWeek.startDate === timePlan.startDate);

      if (foundPlans) {
        const updatedPlans =
            timePlan.plans[userId] &&
            timePlan.plans[userId].length > 0 &&
            [
              ...timePlan.plans[userId],
              ...foundPlans.plans
            ] ||
            foundPlans.plans;

        return {
          ...timePlan,
          plans: {
            ...timePlan.plans,
            [userId]: updatedPlans
          },
        }
      }

      return timePlan;
    });

    const updatedWeeks = this.members[userDetails.position][userDetails.name].weeks.map((week) => {
      const currentWeek = response.find((selectedWeek) => selectedWeek.startDate === week.startDate && week.endDate === selectedWeek.endDate);
      if (currentWeek) {
        const { plans: [newProject] } = currentWeek;
        const { isNonBillable, timesheetColor } = this.allProjects.find((project) => project.id === newProject.id) || { timesheetColor: null, isNonBillable: false }
        return {
          ...week,
          projectsTimes: [
            ...week.projectsTimes,
            {
              ...newProject,
              name: newProject.projectName,
              id: newProject.projectId,
              planId: newProject.id,
              planMs: newProject.totalMilliseconds,
              durationMs: 0,
              isNonBillable,
              timesheetColor
            }
          ]
        }
      }
      return week
    });

    this.members = {
      ...this.members,
      [userDetails.position]: {
        ...this.members[userDetails.position],
        [userDetails.name]: {
          ...this.members[userDetails.position][userDetails.name],
          weeks: updatedWeeks
        }
      }
    };

    this.timePlans = timePlans;
    this.weekTimes = weekTimes;

    this.setMembersWeekTimes(this.weekTimes);
    this.setMembersVacations(this.vacations, SERVICE_DATA_VACATION);
    this.setMembersVacations(this.sickDays, SERVICE_DATA_SICK_DAY);
    this.setHolidays(this.holidays);
    this.setWorkdays(this.workdays)
  }

  @Watch('weekTimes')
  updateSelectedWeeks(weekTimes: IWeekTime[]) {
    if (this.selectedWeeks.length > 0) {
      const userId = this.selectedWeeks[0].id;
      const selectedWeeks = this.selectedWeeks.map((selectedWeek) => {
        const currentWeek = weekTimes.find(
            (weekTime) => weekTime.endDate === selectedWeek.endDate && weekTime.startDate === selectedWeek.startDate);
        const currentWeekProjects =
            currentWeek &&
            currentWeek.usersTimes &&
            currentWeek.usersTimes[userId] &&
            currentWeek.usersTimes[userId].projectsTimes || [];
        return {
          ...selectedWeek,
          projectsTimes: currentWeekProjects && currentWeekProjects.length > 0 ?
              currentWeekProjects.map((project) => {
                const editedUserName = this.team.find(({ id }) => id === project.editedUserId);
                return {
                  ...project,
                  editedUserName: editedUserName ? editedUserName.name : null,
                };
              })
              : []
        };
      })
      this.selectedWeeks = selectedWeeks;
    }
  }

  async changeUserProject({ project, previous, userDetails }) {
    const selectedWeeks = this.selectedWeeks;
    const userId = selectedWeeks[0].id;
    const isAsap = project.isAsap || false;
    const projectId = project.id;

    const plans = selectedWeeks.map(({ startDate, endDate, ...week }) => {
      const projectWeek = week.projectsTimes.find(
          (projectWeek) => projectWeek.name === project.name || projectWeek.name === previous.name);
      const id = projectWeek ? projectWeek.planId : null;

      return {
        startDate,
        endDate,
        isAsap,
        totalMilliseconds:
            project.name === previous.name && project.isAsap === previous.isAsap
                ? project.planMs
                : projectWeek && projectWeek.planMs,
        userId,
        projectId,
        isNonBillable: project.isNonBillable,
        timesheetColor: project.timesheetColor,
        id,
        durationMs: project.durationMs
      };
    });

    if (project.isAsap === previous.isAsap && plans.every(({ totalMilliseconds }) => !totalMilliseconds)) {
      const planIds = plans.filter(({ id }) => id).map(({ id }) => id);
      await this.$store.dispatch('RemoveProject', { planIds });

      const weekTimes = this.weekTimes.map((weekTime) => {
        const currentWeek = plans.find((plan) => plan.startDate === weekTime.startDate && plan.endDate === weekTime.endDate);
        const userProjects = weekTime.usersTimes &&
            weekTime.usersTimes[userId] &&
            weekTime.usersTimes[userId].projectsTimes || [];

        if (currentWeek) {
          const currentProject = userProjects.find(({ planId }) => planIds.find((id) => planId === id));

          if (currentProject && !currentProject.durationMs) {
            const filteredUserProjects = userProjects.filter(({ id, durationMs }) => !(id === project.id && !durationMs));
            return {
              ...weekTime,
              usersTimes: {
                ...weekTime.usersTimes,
                [userId]: {
                  projectsTimes: filteredUserProjects
                }
              }
            }
          }

          if (currentProject) {
            const updateUserProjects = userProjects.map((userProject) => {
              if (userProject.id === project.id) {
                return {
                  ...userProject,
                  planId: null,
                  planMs: 0
                }
              }
              return userProject;
            });

            return {
              ...weekTime,
              usersTimes: {
                ...weekTime.usersTimes,
                [userId]: {
                  projectsTimes: updateUserProjects
                }
              }
            }
          }

          if (!currentProject) {
            const filteredUserProjects = userProjects.filter(({ id }) => id !== project.id);
            return {
              ...weekTime,
              usersTimes: {
                ...weekTime.usersTimes,
                [userId]: {
                  projectsTimes: filteredUserProjects
                }
              }
            }
          }


        }
        return weekTime;
      });

      const timePlans = this.timePlans.map((timePlan) => {
        const currentWeek = plans.find((plan) => (plan.startDate as any) === timePlan.startDate);

        if (currentWeek) {
          const updatedPlans =
              timePlan.plans[userId] &&
              timePlan.plans[userId].length > 0 &&
              timePlan.plans[userId].filter((plan) => plan.projectId !== project.id) || [];

          return {
            ...timePlan,
            plans: {
              ...timePlan.plans,
              [userId]: updatedPlans
            }
          }
        }
        return timePlan;
      })

      const updatedWeeks = this.members[userDetails.position][userDetails.name].weeks.map((week) => {
        const currentWeek = plans.find((plan) => plan.startDate === week.startDate)
        if (currentWeek) {
          return {
            ...week,
          }
        }
        return week
      });

      this.members = {
        ...this.members,
        [userDetails.position]: {
          ...this.members[userDetails.position],
          [userDetails.name]: {
            ...this.members[userDetails.position][userDetails.name],
            weeks: updatedWeeks
          }
        }
      };

      this.timePlans = timePlans;
      this.weekTimes = weekTimes;
    } else {
      const response = await this.$store.dispatch('SaveProject', { plans: plans.map(({ durationMs, isNonBillable, timesheetColor, ...rest }) => rest) });

      const weekTimes = this.weekTimes.map((weekTime) => {
        const currentWeek = response.find((selectedWeek) => selectedWeek.startDate === weekTime.startDate && weekTime.endDate === selectedWeek.endDate);

        const userProjects =
            weekTime.usersTimes &&
            weekTime.usersTimes[userId] &&
            weekTime.usersTimes[userId].projectsTimes || [];

        if (currentWeek) {
          const updatedProjectsTimes = userProjects.map((userProject) => {
            const changedProject = currentWeek.plans.find((plan) => plan.projectId === userProject.id);
            if (changedProject) {
              const oldProject = userProjects.find((project) => project.id === changedProject.projectId);
              return {
                ...changedProject,
                id: changedProject.projectId,
                planMs: changedProject.totalMilliseconds,
                planId: changedProject.id,
                name: changedProject.projectName,
                durationMs: oldProject ? oldProject.durationMs : 0,
                isNonBillable: oldProject ? oldProject.isNonBillable : false,
                timesheetColor: oldProject ? oldProject.timesheetColor : 0
              };
            }

            return userProject;
          });

          const findProject = userProjects.find((userProject) => currentWeek.plans.find((plan) => plan.projectId === userProject.id));
          if (!findProject) {
            const updatedProjectsTimes = [
              ...userProjects.filter(({ id }) => id !== previous.id),
              ...currentWeek.plans.map((plan) => ({
                ...plan,
                id: plan.projectId,
                planMs: plan.totalMilliseconds,
                planId: plan.id,
                name: plan.projectName,
                durationMs: 0
              }))
            ];

            return {
              ...weekTime,
              usersTimes: {
                ...weekTime.usersTimes,
                [userId]: {
                  projectsTimes: updatedProjectsTimes
                }
              }
            };
          }

          return {
            ...weekTime,
            usersTimes: {
              ...weekTime.usersTimes,
              [userId]: {
                projectsTimes: updatedProjectsTimes
              }
            }
          }
        }
        return weekTime;
      });

      const timePlans = this.timePlans.map((timePlan) => {
        const currentWeek = response.find((selectedWeek) => selectedWeek.startDate === timePlan.startDate);
        if (currentWeek) {
          const userPlans = timePlan.plans[userId] &&
              timePlan.plans[userId].length > 0 &&
              timePlan.plans[userId] || [];

          const currentProject = userPlans.find((userPlan) => currentWeek.plans.find((plan) => plan.projectId === userPlan.projectId));

          if (userPlans.length > 0 && !currentProject) {
            const updatedPlans = [
              ...userPlans.filter(({ projectId }) => projectId !== previous.id),
              ...currentWeek.plans
            ];
            return {
              ...timePlan,
              plans: {
                ...timePlan.plans,
                [userId]: updatedPlans
              }
            }
          }

          const updatedPlans =
              userPlans.length > 0 &&
              userPlans.map((plan) => {
                const updatedProject = currentWeek.plans.find((newPlan) => plan.id === newPlan.id)

                if (!this.allProjects && updatedProject) {
                  return { ...updatedProject };
                }
                const { isNonBillable, timesheetColor } = this.allProjects.find((project) => currentWeek.plans.find((plan) => plan.projectId === project.id)) || { isNonBillable: false, timesheetColor: null }

                if (updatedProject) {
                  return {...updatedProject, isNonBillable, timesheetColor};
                }
                return plan;
              }) || [...currentWeek.plans];
          return {
            ...timePlan,
            plans: {
              ...timePlan.plans,
              [userId]: updatedPlans
            }
          }
        }
        return timePlan;
      });

      const updatedWeeks = this.members[userDetails.position][userDetails.name].weeks
          .map((week) => {
            const currentWeek = response.find((selectedWeek) => selectedWeek.startDate === week.startDate && selectedWeek.endDate === week.endDate);

            if (currentWeek) {
              if (!week.projectsTimes.length) {
                const projectsTimes = [...currentWeek.plans]
                    .map((plan) => {
                      const { isNonBillable, timesheetColor } = this.allProjects.find((project) => project.id === plan.projectId) || { isNonBillable: false, timesheetColor: null }
                      return {
                        ...plan,
                        id: plan.projectId,
                        planMs: plan.totalMilliseconds,
                        planId: plan.id,
                        name: plan.projectName,
                        isNonBillable: isNonBillable,
                        timesheetColor: timesheetColor
                      }
                    })
                return {
                  ...week,
                  projectsTimes,
                }
              }
              const updatedWeek =
                  week.projectsTimes.length &&
                  week.projectsTimes.find(({ id }) => currentWeek.plans.find(({ projectId }) => id === projectId)) &&
                  week.projectsTimes.map((projectTime) => {
                    const currentProject = currentWeek.plans.find(({ projectId }) => projectTime.id === projectId);
                    if (currentProject) {
                      return {
                        ...projectTime,
                        id: currentProject.projectId,
                        planMs: currentProject.totalMilliseconds,
                        planId: currentProject.id,
                        name: currentProject.projectName,
                      }
                    }
                    return projectTime
                  }) || [
                    ...week.projectsTimes.filter(({ id }) => id !== previous.id),
                    ...currentWeek.plans.map((plan) => ({
                      ...plan,
                      id: plan.projectId,
                      planMs: plan.totalMilliseconds,
                      planId: plan.id,
                      name: plan.projectName,
                    }))
                  ];
              return {
                ...week,
                projectsTimes: updatedWeek
              }
            }

            return week;
          });
      this.members = {
        ...this.members,
        [userDetails.position]: {
          ...this.members[userDetails.position],
          [userDetails.name]: {
            ...this.members[userDetails.position][userDetails.name],
            weeks: updatedWeeks
          }
        }
      };
      this.timePlans = timePlans;
      this.weekTimes = weekTimes;
    }
    this.setMembersWeekTimes(this.weekTimes);
    this.setMembersVacations(this.vacations, SERVICE_DATA_VACATION);
    this.setMembersVacations(this.sickDays, SERVICE_DATA_SICK_DAY);
    this.setHolidays(this.holidays);
    this.setWorkdays(this.workdays)
  }

  startSearch(value): void {
    // prevent prev postponed search from start
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    //start search after 1 sec
    this.searchTimeout = setTimeout(() => {
      this.search = value
    }, 300)
  }

  updComments(data: {dayData: IDayData, userDeteils: ITableMember}) {
    if(!data.dayData.comments || data.dayData.comments.length === 0) {
      this.clearMembersComments(data.dayData, data.userDeteils);
    }
    else {
      this.setMembersComments(data.dayData.comments);
    }

    this.table_content_reload++;
  }
}
